<template>
	<div>
		<van-nav-bar
			title="文章详情"
			left-text="返回"
			left-arrow
            fixed
			@click-left="onClickLeft"
		/>
		
        <h-40></h-40>
		
		<div class="m-10">
			<h1 class="font-20 font-b">{{articleData.title}}</h1>
			<div class="l-h-40 grey font-14">发布时间：{{articleData.created_at}}</div>
			<div class="article-content font-16">
				<div v-if="videoData.fhash" class="test_two_box m-t-20 m-b-20">
					<video :id="'V'+videoData.vid" class="video-js">
						<source :src="videoData.fhash" type="application/x-mpegURL" />
					</video>
				</div>
				<div v-if="articleData.detail" v-html="articleData.detail.content" class="font-16 detail-content" style="font-weight: normal;"></div>
			</div>
            
            <div>
                <div class="font-13">
                    <div class="l-h-50">
                        <span class="m-r-20">文章分类</span>
                        <van-tag plain type="success" @click.stop="openLanmu">{{labelName}}</van-tag>
                    </div>
                    <div class="flex flex-ai-c">
                        <div class="m-r-20">文章属性</div>
                        <div class="label-item flex flex-w-n flex-ai-c">
                            <div v-if="articleData.zhiding" class="flex flex-ai-c m-r-10 zhiding">
                                <van-icon name="back-top" size="16"/><span>置顶</span>
                            </div>
                            <div v-if="articleData.tuijian" class="flex flex-ai-c m-r-10 tuijian">
                                <van-icon name="good-job-o" size="16"/><span>推荐</span>
                            </div>
                            <div v-if="articleData.jinghua" class="flex flex-ai-c m-r-10 jinghua">
                                <van-icon name="label-o" size="16"/><span>精华</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
		</div>
		
		<common-footer divider></common-footer>
	</div>
</template>

<script>
	export default {
		components:{
		},
		data() {
			return {
				is_video:false,
                articleId:'',
                articleData:'',
                labelName:'',
                videoData:[],
                videoObj:''
			}
		},
		mounted() {
            if(!this.$route.query.id){
                this.$toast.fail('无文章id')
                setTimeout(() => {
                    this.$router.go(-1);
                },2500)
                return false
            }
            this.articleId = this.$route.query.id
            
            this.getArticle()
            
            
            
            
            // let d = document.getElementsByTagName('figure');
            
            
            // console.log(d)
            
            
		},
		methods:{
			onClickLeft() {
				// this.$toast('返回');
				this.$router.go(-1);
			},
            getArticle(){                
                this.$api.article.getDetails(this.articleId).then((res) => {                    
                    this.articleData = res.data
                    this.labelName = this.articleData.cateogry.name
                    this.getVideo(this.articleData.id)
                    setTimeout(() => {
                        let figure = document.getElementsByTagName('figure')
                        for(let i=0;i<figure.length;i++){
                            figure[i].style.width='100%'
                        }
                    },50)
                })
            },
            getVideo(id){
                this.$api.video.getVideoById(id).then((res) => {
                    this.videoData = res.data
                    if(res.data != ''){
                        setTimeout(() => {
                            this.videoEvent(this.videoData.vid)
                        },200)
                    }
                })
            },
            videoEvent(id){
                this.videoObj = this.$video('V'+id, {
                    //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
                    controls: true,
                    poster: '/video/oceans.png', //封面
                    //自动播放属性,muted:静音播放
                    // autoplay: "muted",
                    //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
                    // preload: "auto",
                    //设置视频播放器的显示宽度（以像素为单位）
                    width: "300",
                    //设置视频播放器的显示高度（以像素为单位）
                    height: "300"
                });
            },
            openLanmu(){
                this.$router.push({
                    path:'/article/list',
                    query:{
                        slug:this.articleData.cateogry.slug
                    }
                })
            },
		},
        beforeDestroy() {
            if(this.videoObj) this.videoObj.dispose()
        }
	}
</script>

<style>
    .label-item span{
        font-size: 13px;
        margin-left: 2px;
        /* border: 1px solid #FF0000; */
    }
    .label-item .zhiding{
        color: #FF6600;
    }
    .label-item .tuijian{
        color: #FF0000;
    }
    .label-item .jinghua{
        color: #07c160;
    }
    
    
	.article-content img{
		width: 100%;
		margin: 15px auto;
	}
	.article-content p{
		line-height: 25px;
		text-align:justify;
		text-justify:inter-ideograph;
	}
    .ck-widget__type-around__button ,.ck-widget__resizer{
        display: none;
    }
    figure{
        margin: 0;
        padding: 0;
    }
    
    .detail-content p{
        text-indent: 2em;
    }
    
    .vjs-poster{
        background-size: auto;
    }
    
    .vjs-paused .vjs-big-play-button,
    .vjs-paused.vjs-has-started .vjs-big-play-button {
        display: block;
    } 
    .video-js .vjs-big-play-button{
        font-size: 2.5em;
        line-height: 2.3em;
        height: 2.5em;
        width: 2.5em;
        -webkit-border-radius: 2.5em;
        -moz-border-radius: 2.5em;
        border-radius: 2.5em;
        background-color: #73859f;
        background-color: rgba(115,133,159,.5);
        border-width: 0.15em;
        position: absolute;
        top: 33%;
        left: 40%;
    }
    /* 中间的播放箭头 */
    .vjs-big-play-button .vjs-icon-placeholder {
        font-size: 1.63em;
    }
    /* 加载圆圈 */
    .vjs-loading-spinner {
        font-size: 2.5em;
        width: 2em;
        height: 2em;
        border-radius: 1em;
        margin-top: -1em;
        margin-left: -1.5em;
    }
</style>
